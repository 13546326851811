export const ALLOWED_CHARACTERS = <const>{
  b10: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  b11: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|<'\"",
  b12: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|<'\"",
  b13: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  ba2: "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  ba3: "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  ba4: "1234567890",
  ba5: "1234567890",
  ban: "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  be2: "1234567890.,",
  be3: "1234567890.,",
  be4: "1234567890.,",
  be5: "1234567890.,",
  be6: "1234567890-,.",
  bed: "1234567890.,",
  bel: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bes: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bl2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bla: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bls: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs3: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs4: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs5: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs6: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  bs7: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|<'\"",
  bs8: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|<'\"",
  bs9: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|<'\"",
  cd1: "1234567890",
  cd2: "1234567890",
  cd3: "1234567890",
  cd4: "1234567890",
  cd5: "1234567890",
  cd6: "1234567890",
  cd7: "1234567890",
  cd8: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  cd9: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  cdf: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.[]-",
  cdp: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.[]-",
  cdx: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.[]-",
  chx: "0123456789abcdef",
  cdy: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.[]-",
  clk: "1234567890",
  da2: "1234567890-",
  da3: "1234567890-",
  da4: "1234567890-",
  dat: "1234567890-",
  dis: "1234567890-TZ",
  di3: "1234567890-TZ",
  de2: "1234567890-,.",
  dec: "1234567890-,.",
  em2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.@-_",
  eml: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.@-_",
  fil: null,
  gk1: null,
  gk2: null,
  gri: null,
  gt2: "1234567890",
  gt3: "1234567890",
  gt4: "1234567890",
  gt5: "1234567890",
  gt6: "1234567890",
  gt7: "1234567890-",
  gtp: "1234567890",
  hi2: null,
  hi3: null,
  hid: null,
  htv: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-. ",
  hu2: "1234567890",
  hui: "1234567890",
  ico: null,
  idf: null,
  idg: null,
  idh: null,
  idi: null,
  idj: null,
  idk: null,
  idl: null,
  idm: null,
  idn: null,
  ido: null,
  idp: null,
  idq: null,
  idr: null,
  ids: null,
  idt: null,
  idu: null,
  idx: null,
  idy: null,
  idz: null,
  img: null,
  ipa: ".1234567890",
  ja2: null,
  jaa: null,
  jan: null,
  jn2: null,
  jn3: null,
  jn4: null,
  jn5: null,
  jn6: null,
  knp: null,
  kob: null,
  koh: null,
  kok: null,
  kop: null,
  lbl: "1234567890lL",
  lda: "1234567890-,.",
  ldn: "1234567890",
  ln2: null,
  ln3: null,
  ln4: null,
  lnk: null,
  lur: "1234567890-,.",
  naa: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  nud: "1234567890-,.",
  nup: "1234567890-,.",
  nv2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  nvl: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  nvv: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ/ ",
  om2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om3: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om4: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om5: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om6: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om7: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  om8: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ/ ",
  oml: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  oms: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  ord: null,
  pa2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  pa3: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  pa4: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  pad: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  pas: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  pdp: "1234567890-,.",
  pe2: "1234567890",
  per: "1234567890",
  pl2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_/",
  pl3: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_/",
  plk: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_/",
  po2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  pos: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  pr2: "1234567890",
  pr3: "1234567890",
  pr4: "1234567890,",
  pr5: "1234567890,",
  pr6: "1234567890,",
  pr7: "1234567890,",
  prc: "1234567890,",
  pre: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  prv: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
  rij: "1234567890",
  rrn: "1234567890",
  sal: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  sc2: "1234567890",
  sc3: "1234567890",
  sch: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  sl2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.[]-",
  slt: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890[]",
  sof: "1234567890",
  te2: "1234567890-+()",
  tel: "1234567890-+()",
  ti2: "1234567890:",
  tid: "1234567890:",
  tij: null,
  to2: null,
  to3: null,
  toe: null,
  uu2: "1234567890.",
  uu3: "1234567890,",
  uur: "1234567890.",
  ver: "1234567890.",
  vgp: "1234567890,",
  vol: null,
  vri: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  wa2: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  wa3: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  wac: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  wah: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  xb2: null,
  xbl: null,
  xdt: null,
  xem: null,
  xja: null,
  xpr: null,
  xxx: null,
  pnl: null,
  qrc: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  sao: null,
  uu4: "1234567890,",
  pr8: "1234567890,",
  gr2: null,
  gr3: null,
  om9: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890* @!#$%&_()'?/\\+±>[]¦;,:.-=âäáàêëéèïôöóòûüúùß|",
  jsn: null,
  coi: null,
  qa1: null,
  qa2: null,
  qa3: null,
  klr: null,
  bin: null,
  gui: null,
};
