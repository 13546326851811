import { EnvironmentHelper } from "src/classes/EnvironmentHelper";
import { OrganisationConfig } from "src/interfaces/environment";

const CONFIG: OrganisationConfig = {
  NAME: "wbb",
  TITLE: "Werkbaarbeter",
  API: null,
  WEBSOCKET: null,
  SHOW_HISTORY: true,
  QR_LOGIN: true,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  MSAL: {
    clientId: "6dd11a7d-d362-4ff5-890f-0e934b2ca24a",
    tenantId: "6137e509-173d-4076-afc4-67b476042cf9",
  },
  SUBFUNCTIONALITIES: ["VIDEO"],
  MAP: {
    KEY: "AIzaSyDdyPDZ3YvV8LpUSEiMf5oUUNGl3RRoiHo",
    ID: "aa03588413f4c28d",
  },
};

const CONFIG_DEVELOPMENT: Partial<OrganisationConfig> = {
  TITLE: "Werkbaarbeter | Development",
  API: "https://api.dev-core.werkbaarbeter.net",
  WEBSOCKET: "https://api.dev-core.werkbaarbeter.net/socket",
};

const CONFIG_TEST: Partial<OrganisationConfig> = {};
const CONFIG_STAGING: Partial<OrganisationConfig> = {};

const CONFIG_PRODUCTION: Partial<OrganisationConfig> = {};

export const ORGANISATION = EnvironmentHelper.GetEnvironment({
  CONFIG,
  CONFIG_DEVELOPMENT,
  CONFIG_TEST,
  CONFIG_STAGING,
  CONFIG_PRODUCTION,
});
