import { NgModule } from "@angular/core";
import { MSAL_INSTANCE, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { ORGANISATION } from "src/organisation/organisation";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
  ],
})
export class SsoModule {}

export function MSALInstanceFactory(): IPublicClientApplication {
  const clientId = ORGANISATION.MSAL?.clientId || "";
  const authority = ORGANISATION.MSAL ? `https://login.microsoftonline.com/${ORGANISATION.MSAL.tenantId}` : undefined;

  return new PublicClientApplication({
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: "/login/sso",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false,
    },
  });
}
