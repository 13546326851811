import { ENVIRONMENT } from "src/environments/environment";
import { OrganisationConfig } from "src/interfaces/environment";

interface Environments {
  CONFIG: OrganisationConfig;
  CONFIG_DEVELOPMENT: Partial<OrganisationConfig>;
  CONFIG_TEST: Partial<OrganisationConfig>;
  CONFIG_STAGING: Partial<OrganisationConfig>;
  CONFIG_PRODUCTION: Partial<OrganisationConfig>;
}

export enum EnvironmentStatus {
  LOCAL = "LOCAL",
  DEVELOPMENT = "DEVELOPMENT",
  TEST = "TEST",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}

export interface EnvironmentConfig {
  DEBUG: boolean;
  STATUS: EnvironmentStatus;
}

export abstract class EnvironmentHelper {
  public static GetEnvironment(environments: Environments): OrganisationConfig {
    switch (ENVIRONMENT.STATUS) {
      case EnvironmentStatus.DEVELOPMENT:
        return { ...environments.CONFIG, ...environments.CONFIG_DEVELOPMENT };
      case EnvironmentStatus.TEST:
        return { ...environments.CONFIG, ...environments.CONFIG_TEST };
      case EnvironmentStatus.STAGING:
        return { ...environments.CONFIG, ...environments.CONFIG_STAGING };
      case EnvironmentStatus.PRODUCTION:
        return { ...environments.CONFIG, ...environments.CONFIG_PRODUCTION };
      case EnvironmentStatus.LOCAL:
        return {
          ...environments.CONFIG,
          ...environments.CONFIG_DEVELOPMENT,
          ...{
            API: "http://localhost:19720",
          },
        };
    }
  }
}
